import React, { useState } from 'react';

interface Message {
  text: string;
  type: 'user' | 'bot';
}

const Chatbot: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Add the user's message to the chat
    const newMessage: Message = { text: input, type: 'user' };
    setMessages([...messages, newMessage]);

    // Send the message to the backend
    try {
      const response = await fetch('https://blockgan.com/api/chatbot/chat/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const botMessage: Message = { text: data.response, type: 'bot' };
      setMessages([...messages, newMessage, botMessage]);
    } catch (error) {
      const errorMessage: Message = { text: `Network error: ${error instanceof Error ? error.message : 'Unknown error'}`, type: 'bot' };
      setMessages([...messages, newMessage, errorMessage]);
    }

    // Clear the input field
    setInput('');
  };

  return (
    <div className="chatbox-container bg-gray-100 p-4 max-w-lg mx-auto rounded-lg shadow-md">
      <div className="messages-container overflow-y-auto h-80 p-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.type === 'user' ? 'text-right' : 'text-left'} p-2 mb-2`}
          >
            <div
              className={`inline-block rounded-lg p-2 ${
                message.type === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="mt-4 flex">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="flex-grow p-2 border border-gray-300 rounded-l-lg"
          placeholder="Type your message..."
        />
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-r-lg hover:bg-blue-600"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default Chatbot;